.navbar {
  background-color: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 20;
  padding: 0px !important;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 0 10px 30px; */
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #243A7D;
  white-space: nowrap;
  transition: 0.3s;
  text-decoration: none;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-container .logo>h1 {
  color: #243A7D;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: .5px;
}

.nav-container .logo>h1>span {
  color: #FBC02D;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: .2px;
}

/* .nav-container .logo img {
  max-height: 40px;
  margin-right: 6px;
} */

.nav-container .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

.nav-logo {
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #FBC02D;
}

.nav-item .active {
  color: #FBC02D;
}

.nav-icon {
  display: none;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar li:hover>a {
  color: #1B75BB;
}

.navbar .getstarted {
  background: #243A7D;
  padding: 0px 20px;
  /* margin-left: 30px; */
  /* margin: 0px 5px; */
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #2041a1;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  /* left: 14px; */
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 5px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #4154f1;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media screen and (max-width: 960px) {
  .navbar {
    height: 60px;
  }

  .nav-container {
    height: 65px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 90%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-link {
    color: #006fae !important;
  }

  .navbar a {
    color: #fff;
    padding: 0 !important;
    padding-left: 15px !important;
  }

  .navbar .getstarted {
    margin: 8px 0 8px 0 !important;
    padding: 2px 8px !important;
  }

  .nav-menu.active {
    background: #006fae;
    color: #fff;
    left: 0px;
    top: 67px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    width: 100%;
  }

  .nav-item .active {
    /* color: rgb(249, 115, 25); */
    color: #FBC02D;
    border: none;
  }

  .nav-links {
    color: #fff;
    padding: 1rem;
    width: 90%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    transform: translate(-100%, 60%);
    font-size: 0.8rem;
    cursor: pointer;
    color: #006fae;
  }

  .material-symbols-outlined {
    font-size: 34px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #ffffff !important;
  }

  ul:not(.u-unstyled) {
    margin-top: -20px !important;
  }
}