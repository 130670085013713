.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}


.countbox {
  box-shadow: 0px 0px 8px 0px #00000047;
  border-radius: 20px;
  padding: 10% 10%;
  text-align: center;
  margin: 0 5%;
}

/*#barGraph .recharts-cartesian-axis-tick-value{
  writing-mode: tb-rl;
}*/

.countbox h3 {
  color: #FBC02D;
}

.container {
  padding: 1% 2%;
}

.graphbox {
  padding: 10px 10px 15px 10px;
  box-shadow: 0px 0px 12px 1px #00000040;
  border-radius: 29px;
  margin: 0 2% 0 0;
  height: fit-content;
}

.speedometerBox {
  display: inline-flex;
  padding: 6px 5px !important;
}

.speedometerBox p {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
  letter-spacing: 0.5px;
}



@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .overflow{
    overflow-x: auto;
  }
  .graphbox{
    margin-right: 28px;
    width: 54% !important;
  }
  .rowMargin{
    margin-top: 24px !important;
  }
  .width{
    width: 174% !important;
  }
}

@media(max-width:576px){
  .graphbox{
    margin-right: 12px;
    width: 100% !important;
  }
  .width{
    width: 330% !important;
  }
}