.test-container {
    width: 90%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    margin: 100px auto 50px;
    background-color: whitesmoke;
}

.test-container>h5 {
    font-size: 20px;
    color: #243A7D;
    margin: 20px 0 24px 40px;
}

.tableDiv {
    display: flex;
    justify-content: center;
}

.tableDiv table {
    width: 85%;
}

.tableDiv th {
    background-color: #FBC02D;
    color: #313030;
    height: 48px;
    font-size: 18px;
}

.tableDiv th,
.tableDiv td {
    border: 1px solid #262626;
    text-align: center;
}

.tableDiv td {
    height: 48px;
}

.tableDiv td p {
    margin: 8px 0px;
}

tbody>tr>td>button {
    background-color: #243A7D !important;
    color: #ffffff !important;
}

/* tbody>tr>td>button:hover {
    background-color: #243A7D !important;
} */

.test-container>button {
    /* width: 14%; */
    color: #ffffff;
    /* background-color: #243A7D; */
    background-color: #243A7D;
    margin: 50px auto;
    padding: 6px 18px;
    font-size: 18px;
    letter-spacing: .8px;
    border-radius: 6px;
    border: none;
}

.test-container>button:hover {
    background-color: #243A7D;
}

.test-result {
    width: 90%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    background-color: whitesmoke;
}