.exam-container {
    width: 90%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    margin: 100px auto 50px;
    padding: 20px;
    background-color: whitesmoke;
}

.exam-info {
    border-bottom: 2px solid rgb(243, 67, 37);
}

.startExamBtn {
    text-align: center !important;
    border-radius: 6px !important;
    background-color: #243A7D !important;
    border: 1px solid #243A7D !important;
    color: #fff !important;
    padding: 6px 16px;
    margin-top: 8px;
    font-size: 20px;
}