.inp-group {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 32px;
    margin-bottom: 30px;
}

.inp-group input,
.ant-select-selector {
    width: 100%;
    height: 40px !important;
    padding: 2px 12px;
    border-radius: 12px !important;
    border: 1px solid #243A7D !important;
    color: #555 !important;
}

input[type="search"] {
    border: none !important;
}

.input-n-btn {
    display: flex;
    width: 100%;
}

.input-n-btn>input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.verify-btn {
    text-wrap: nowrap;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    background-color: #243A7D !important;
    border: 1px solid #243A7D !important;
    color: #fff;
    padding: 0px 16px;
}

@media (max-width:768px) {
    .form-group {
        flex-direction: column;
    }

    .inp-group > .ant-select{
        width: 100% !important;
    }
}